// src/pages/Reports/SentDataToCustomersArchive/SentDataToCustomersArchiveUI.jsx

import React from 'react';
import PropTypes from 'prop-types';
import {Button, Grid, Paper, Typography} from '@mui/material';
import AppComponentHeader from '../../../@crema/core/AppComponentHeader';
import AppGridContainer from '../../../@crema/core/AppGridContainer';
import DataSendArchiveFilterCard from 'components/FilterCard/DataSendArchiveFilterCard';
import FilterListIcon from '@mui/icons-material/FilterList';
import {DataGrid} from '@mui/x-data-grid';

const SentDataToCustomersArchiveUI = ({
  sentDataArchive,
  archiveCount,
  page,
  setSelectedDomain,
  setSelectedCustomer,
  onPageChange,
  onFilterChange,
  domains,
  customers,
  handleGenerateReport,
  filters,
  setFilters,
}) => {
  const [isFilterCardVisible, setIsFilterCardVisible] = React.useState(false);

  const toggleFilterCardVisibility = () => {
    setIsFilterCardVisible((prev) => !prev);
  };
  // Function to update domain and customer filters
  const handleDomainChange = (event, newDomain) => {
    setSelectedDomain(newDomain);
    setFilters((prev) => ({
      ...prev,
      domain_id: newDomain ? newDomain.key : '', // Ensure domain ID is being captured
    }));
  };

  const handleCustomerChange = (event, newCustomer) => {
    setSelectedCustomer(newCustomer);
    setFilters((prev) => ({
      ...prev,
      customer_id: newCustomer ? newCustomer.key : '', // Ensure customer ID is being captured
    }));
  };
  // Define columns for the DataGrid
  const columns = [
    {
      field: 'rowNumber',
      headerName: 'Row Number',
      width: 120,
      sortable: false,
      filterable: false,
      valueGetter: (params) => params.api.getRowIndex(params.id) + 1,
    },
    {
      field: 'report_name',
      headerName: 'Report Name',
      width: 250,
      flex: 1,
    },
    {
      field: 'sent_at',
      headerName: 'Sent Date',
      width: 200,
      type: 'date',
      valueGetter: (params) => new Date(params.row.sent_at),
      valueFormatter: (params) => {
        const value = params.value;
        if (value) {
          return new Intl.DateTimeFormat('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          }).format(value);
        }
        return '';
      },
    },
    {
      field: 'customer_name',
      headerName: 'Customer Name',
      width: 200,
      flex: 1,
    },
    {
      field: 'domain_name',
      headerName: 'Sent from Domain',
      width: 200,
      flex: 1,
    },
    {
      field: 'generateReport',
      headerName: 'Generate Report',
      width: 200,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <Button
          variant='contained'
          color='primary'
          onClick={() => handleGenerateReport(params.id)}
        >
          Generate
        </Button>
      ),
    },
  ];

  return (
    <>
      <AppComponentHeader title='Sent Data to Customers Archive' />
      <AppGridContainer>
        {/* Toggle Filter Button */}
        <Grid item xs={12}>
          <Button
            variant='contained'
            startIcon={<FilterListIcon />}
            onClick={toggleFilterCardVisibility}
          >
            {isFilterCardVisible ? 'Hide Filters' : 'Show Filters'}
          </Button>
        </Grid>

        {/* Filter Card Section */}
        {isFilterCardVisible && (
          <Grid item xs={12}>
            <DataSendArchiveFilterCard
              handleDomainChange={handleDomainChange} // Now being used
              handleCustomerChange={handleCustomerChange} // Now being used
              setSelectedDomain={setSelectedDomain} // Ensure this is also defined and passed
              setSelectedCustomer={setSelectedCustomer} // Now being used
              domains={domains}
              customers={customers}
              filters={filters}
              setFilters={setFilters}
              onApply={onFilterChange}
              handleDateRangeChange={(newDateRange) => {
                setFilters((prev) => ({
                  ...prev,
                  dateRange: newDateRange,
                }));
              }}
              onClear={() => {
                const clearedFilters = {
                  dateRange: [null, null],
                  domain: '',
                  customer: '',
                };
                setFilters(clearedFilters);
                onFilterChange(clearedFilters);
              }}
              disableDataSource={false}
            />
          </Grid>
        )}

        {/* Data Grid Section */}
        <Grid item xs={12}>
          <Paper sx={{padding: 2, marginTop: 4}}>
            <Typography variant='h6' sx={{mb: 2}}>
              Sent Data Archive List
            </Typography>
            <DataGrid
              rows={sentDataArchive}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[10]}
              pagination
              paginationMode='server'
              rowCount={archiveCount}
              onPageChange={(newPage) => onPageChange(null, newPage)}
              page={page}
              disableSelectionOnClick
              autoHeight
              getRowId={(row) => row.id} // Ensure each row has a unique 'id'
            />
          </Paper>
        </Grid>
      </AppGridContainer>
    </>
  );
};

SentDataToCustomersArchiveUI.propTypes = {
  sentDataArchive: PropTypes.array.isRequired,
  archiveCount: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  setSelectedDomain: PropTypes.func.isRequired,
  setSelectedCustomer: PropTypes.func.isRequired, // Make sure this is included

  handleGenerateReport: PropTypes.func.isRequired,
  domains: PropTypes.array.isRequired,
  customers: PropTypes.array.isRequired,
  filters: PropTypes.shape({
    dropdown1: PropTypes.string,
    dropdown2: PropTypes.string,
    dateRange: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
    domain: PropTypes.string,
    customer: PropTypes.string,
  }).isRequired,
  setFilters: PropTypes.func.isRequired,
};

export default SentDataToCustomersArchiveUI;
