import React from 'react';
import AppComponentHeader from '../../../@crema/core/AppComponentHeader';
import AppGridContainer from '../../../@crema/core/AppGridContainer';
import Grid from '@mui/material/Grid';
import RowEditControlGrid from './DataController/RowEditControlGrid';
import PropTypes from 'prop-types';
import {Box, Button, Paper} from '@mui/material';
import UploadModal from './UploadModal/UploadModal';
import FilterCard from 'components/FilterCard/FilterCard';
import FilterListIcon from '@mui/icons-material/FilterList';
import {BiExport} from 'react-icons/bi';
import ExportModal from './UploadModal/ExportModal';

const DataUI = ({
  dataToShow,
  pageSize,
  setPageSize,
  exportDataToExcle,
  pageNumber,
  handleSearchChange,
  handleDateRangeChange,
  setPageNumber,
  rowCount,
  dominId,
  onFilterChange,
  exportingData,
  onEditCommit,
  handleChange,
  setPatchEdited,
  gettingData,
  exportingModalOpen,
  exportingModalClose,
  exportingModal,
  toggleFilterCardVisibility,
  isFilterCardVisible,
  handleOnSelect,
  selected,
  allStatusCount,
  doneStatusCount,
  errorStatusCount,
  handleDomainChange,
  setShowUploadModal,
  showUploadModal,
  onUploadSubmitting,
  onDropDownChange,
  onDropDownSearch,
  affiliateId,
  errorData,
  selecteCustomer,
  domainSelected,
  upLoadingData,
  formatDate,
  handleClear,
  userRole,
  deleteData,
  disableDataSource,
  handleCloseModal,
  handleClearFilters,
  filters,
  setFilters,
  scorcessOptions,
  countryOptions,
}) => {
  return (
    <>
      <AppComponentHeader title='Data Table' />
      <AppGridContainer>
        <Grid item xs={12}>
          <Button
            variant='contained'
            startIcon={<FilterListIcon />}
            onClick={toggleFilterCardVisibility}
            sx={{mr: 4}}
          >
            {isFilterCardVisible ? 'Hide Filters' : 'Show Filters'}
          </Button>
          <Button
            variant='contained'
            startIcon={<BiExport />} // Add the filter icon
            onClick={exportingModalOpen}
          >
            {exportingData ? 'Exporting' : 'Export Data'}
          </Button>
        </Grid>

        {isFilterCardVisible && (
          <Grid item xs={12}>
            <FilterCard
              domains={dominId}
              customers={affiliateId}
              countries={countryOptions}
              dataSources={scorcessOptions}
              handleChange={handleChange}
              filters={filters}
              setFilters={setFilters}
              handleDateRangeChange={handleDateRangeChange}
              handleSearchChange={handleSearchChange}
              onApply={onFilterChange}
              onClear={handleClearFilters}
              disableDataSource={disableDataSource}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          {selected.length > 0 && userRole.includes('admin') && (
            <Box mb={2}>
              <Grid container spacing={2}>
                <Grid item>
                  <Button
                    variant='contained'
                    onClick={() => setShowUploadModal(true)}
                  >
                    Upload Data
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant='contained' onClick={deleteData}>
                    Delete
                  </Button>
                </Grid>
              </Grid>
            </Box>
          )}

          <Paper>
            <RowEditControlGrid
              userRole={userRole}
              dataToShow={dataToShow}
              pageSize={pageSize}
              setPageSize={setPageSize}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              rowCount={rowCount}
              onFilterChange={onFilterChange}
              onEditCommit={onEditCommit}
              setPatchEdited={setPatchEdited}
              gettingData={gettingData}
              handleOnSelect={handleOnSelect}
              formatDate={formatDate} // Pass formatDate here
            />
          </Paper>
        </Grid>

        <UploadModal
          open={showUploadModal}
          selecteCustomer={selecteCustomer}
          domainSelected={domainSelected}
          onSubmit={onUploadSubmitting}
          allStatusCount={allStatusCount}
          doneStatusCount={doneStatusCount}
          handleDomainChange={handleDomainChange}
          dominId={dominId}
          errorStatusCount={errorStatusCount}
          handleClose={handleCloseModal}
          onDropDownChange={onDropDownChange}
          onDropDownSearch={onDropDownSearch}
          affiliateId={affiliateId}
          errorData={errorData}
          upLoadingData={upLoadingData}
          handleClear={handleClear}
        />
      </AppGridContainer>
      <ExportModal
        exportingModal={exportingModal}
        exportingModalClose={exportingModalClose}
        exportDataToExcle={exportDataToExcle}
      />
    </>
  );
};

DataUI.propTypes = {
  dataToShow: PropTypes.array.isRequired,
  pageSize: PropTypes.number.isRequired,
  setPageSize: PropTypes.func.isRequired,
  pageNumber: PropTypes.number.isRequired,
  setPageNumber: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
  dominId: PropTypes.array.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onEditCommit: PropTypes.func.isRequired,
  setPatchEdited: PropTypes.func.isRequired,
  gettingData: PropTypes.bool.isRequired,
  handleOnSelect: PropTypes.func.isRequired,
  selected: PropTypes.array.isRequired,
  allStatusCount: PropTypes.number.isRequired,
  doneStatusCount: PropTypes.number.isRequired,
  errorStatusCount: PropTypes.number.isRequired,
  handleDomainChange: PropTypes.func.isRequired,
  setShowUploadModal: PropTypes.func.isRequired,
  showUploadModal: PropTypes.bool.isRequired,
  onUploadSubmitting: PropTypes.func.isRequired,
  onDropDownChange: PropTypes.func.isRequired,
  onDropDownSearch: PropTypes.func.isRequired,
  affiliateId: PropTypes.array.isRequired,
  errorData: PropTypes.any.isRequired,
  upLoadingData: PropTypes.bool.isRequired,
  handleClear: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSearchChange: PropTypes.func.isRequired,
  handleDateRangeChange: PropTypes.func.isRequired,
  formatDate: PropTypes.func.isRequired,
  userRole: PropTypes.array.isRequired,
  exportingData: PropTypes.bool.isRequired,
  deleteData: PropTypes.func.isRequired,
  handleClearFilters: PropTypes.func.isRequired,
  selecteCustomer: PropTypes.bool.isRequired,
  domainSelected: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  exportDataToExcle: PropTypes.func.isRequired,
  exportingModal: PropTypes.bool.isRequired,
  exportingModalClose: PropTypes.func.isRequired,
  exportingModalOpen: PropTypes.func.isRequired,
  disableDataSource: PropTypes.bool.isRequired,
  filters: PropTypes.shape({
    domain: PropTypes.string,
    customer: PropTypes.string,
    country: PropTypes.string,
    dataSource: PropTypes.string,
    dateRange: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
    search: PropTypes.string,
  }).isRequired,
  setFilters: PropTypes.func.isRequired,
  toggleFilterCardVisibility: PropTypes.func.isRequired,
  isFilterCardVisible: PropTypes.bool.isRequired,
  scorcessOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
    }),
  ).isRequired,
  countryOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default DataUI;
