// src/pages/Reports/SentDataToCustomersArchive/SentDataToCustomersArchiveContainer.jsx

import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import SentDataToCustomersArchiveUI from './SentDataToCustomersArchiveUI';
import {fetchError} from '../../../redux/actions'; // Adjust the import path as needed
import {getSentDataToCustomersArchive} from '../apis/sentDataToCustomersArchive';
import jwtAxios from '@crema/services/auth/jwt-auth';
import {saveAs} from 'file-saver';

const SentDataToCustomersArchiveContainer = () => {
  const [sentDataArchive, setSentDataArchive] = useState([]);
  const [archiveCount, setArchiveCount] = useState(0);
  const [selectedDomain, setSelectedDomain] = useState('');
  const [selectedCustomer, setSelectedCustomer] = useState(''); // Define the state

  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState({
    dateRange: [null, null],
    domain_id: '', // Changed from 'domain' to 'domainId'
    customer_id: '', // Added for customer filtering
  });

  // New state for domains and customers
  const [domains, setDomains] = useState([]);
  const [customers, setCustomers] = useState([]);

  const dispatch = useDispatch();
  useEffect(() => {
    loadSentDataArchive(); // This will now use updated filters whenever they change
  }, [filters]);
  useEffect(() => {
    loadSentDataArchive();
  }, [page, filters]);

  // // Fetch domains and customers once when the component mounts
  // useEffect(() => {
  //   const loadDomainsAndCustomers = async () => {
  //     try {
  //       const [domainsData, customersData] = await Promise.all([
  //       ]);
  //       setDomains(domainsData);
  //       setCustomers(customersData.customers);
  //     } catch (error) {
  //       dispatch(fetchError(error.message));
  //     }
  //   };

  //   loadDomainsAndCustomers();
  // }, [dispatch]);

  const getCustomers = (pageSize, pageNumber, search) => {
    const url = search
      ? `customer/search?pageSize=${pageSize}&pageNumber=${pageNumber}&term=${search}`
      : `customer?pageSize=${pageSize}&pageNumber=${pageNumber}`;
    jwtAxios
      .get(url)
      .then((e) => {
        console.log('e.data.data.data');

        console.log(e.data.data.data);

        setCustomers(e.data.data.data);
        // setCustomersCount(e.data.data.dataCount);
      })
      .catch((e) => {
        dispatch(fetchError(e.message));
      });
  };
  const getDomins = (pageSize, pageNumber) => {
    const url = `domain/search?pageSize=${pageSize}&pageNumber=${pageNumber}&term=`;
    jwtAxios
      .get(url)
      .then((e) => {
        setDomains(
          e.data.data.map((item) => {
            console.log(item);
            return {
              label: `${item.domain_name}`,
              key: item.id,
            };
          }),
        );
      })
      .catch((e) => {
        dispatch(fetchError(e.message));
      });
    console.log('finish fetch');
  };
  // Fetch domains when the component mounts
  useEffect(() => {
    const getDataTimeout = setTimeout(() => {
      getDomins(10, 0);
    }, 1000);
    return () => clearTimeout(getDataTimeout);
  }, []); // Added searchTerm as a dependency
  // Fetch domains when the component mounts
  useEffect(() => {
    const getDataTimeout = setTimeout(() => {
      getCustomers(20, 0);
    }, 1000);
    return () => clearTimeout(getDataTimeout);
  }, []); // Added searchTerm as a dependency

  const loadSentDataArchive = async () => {
    try {
      const {data, count} = await getSentDataToCustomersArchive(page, filters);
      console.log('getSentDataToCustomersArchive data');

      console.log(data);

      setSentDataArchive(data);
      setArchiveCount(count);
    } catch (error) {
      dispatch(fetchError(error.message));
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
    setPage(0); // Reset to the first page when filters change
  };
  const generateReport = (reportId) => {
    return jwtAxios
      .post('/archive/export/', {
        report_id: reportId,
      }) // Use POST and send report_id in the body
      .then((response) => response.data) // response.data already contains the parsed JSON
      .catch((error) => {
        throw new Error(
          error.response?.data?.message || 'Failed to generate report',
        );
      });
  };

  const handleGenerateReport = async (reportId) => {
    try {
      const response = await generateReport(reportId); // This should return the response object

      // Since the response is already parsed, directly destructure the needed values
      const {data, fileName} = response; // Get JSON data directly

      // Create a Blob from the Base64 string
      const byteCharacters = atob(data); // Decode the Base64 string
      const byteNumbers = new Uint8Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const blob = new Blob([byteNumbers], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      // Use FileSaver to save the blob as a file
      saveAs(blob, fileName);
      console.log('Report downloaded successfully:', fileName);
    } catch (error) {
      console.error('Error generating report:', error);
      dispatch(fetchError(error.message)); // Display error message in the UI
    }
  };

  return (
    <SentDataToCustomersArchiveUI
      sentDataArchive={sentDataArchive}
      archiveCount={archiveCount}
      page={page}
      handleGenerateReport={handleGenerateReport}
      onPageChange={handlePageChange}
      selectedCustomer={selectedCustomer}
      onFilterChange={handleFilterChange}
      setSelectedCustomer={setSelectedCustomer}
      domains={domains} // Pass domains to UI
      customers={customers} // Pass customers to UI
      filters={filters} // Pass filters to UI
      selectedDomain={selectedDomain} // Now passed to UI for usage
      setFilters={setFilters} // Pass setFilters to UI for handling in filter card
      setSelectedDomain={setSelectedDomain} // Make sure to pass this function down to the child
    />
  );
};

export default SentDataToCustomersArchiveContainer;
