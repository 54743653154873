import jwtAxios from '@crema/services/auth/jwt-auth';
import {
  Box,
  Button,
  Chip,
  Modal,
  TextField,
  CircularProgress,
  Snackbar,
  InputAdornment,
  IconButton,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchError } from 'redux/actions';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const AttachModal = ({ open, handleClose, customerId }) => {
  const [values, setValues] = useState([]); // Initialize as an empty array
  const [inputValue, setInputValue] = useState(''); // State for input value
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState(''); // State for error messages
  const dispatch = useDispatch();

  const handleOk = async () => {
    setLoading(true);
    console.log('Current values to send:', values); // Check values before sending
    console.log('Request payload:', { dataIds: values }); // Check request payload

    try {
      const response = await jwtAxios.post(`data/archef-data/${customerId}`, {
        dataIds: values,
      });
      console.log('Response:', response.data); // Log response data
      setMessage('Data added successfully!');
      setValues([]); // Clear values after successful send
      setInputValue(''); // Clear input field
      setErrorMessage(''); // Clear error message
      window.location.reload();
    } catch (e) {
      console.error('Error response:', e.response ? e.response.data : e.message);
      dispatch(fetchError(e.message));
      
      // Check for the specific validation error
      if (e.response && e.response.data && e.response.data.message === 'VALIDATION_ERROR') {
        setMessage('Some data IDs were not found. Please make sure the data IDs are valid before proceeding.');
      } else {
        setMessage('Failed to add data.');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = (idToDelete) => {
    setValues((prev) => prev.filter((id) => id !== idToDelete));
  };

  const handleAddValue = (value) => {
    // Check if the input value is a valid number and not already in the values array
    if (value && !isNaN(value)) {
      if (values.includes(value)) {
        setErrorMessage('This ID has already been added.'); // Set error message for duplicates
      } else {
        setValues((prev) => [...prev, value]);
        setInputValue(''); // Clear the input value after adding
        setErrorMessage(''); // Clear any existing error messages
      }
    } else {
      setErrorMessage('Please enter a valid number.'); // Set error message for invalid input
    }
  };

  // New handleClose function to clear values
  const handleModalClose = () => {
    setValues([]); // Clear selected values
    setInputValue(''); // Clear input field
    setErrorMessage(''); // Clear error message
    handleClose(); // Call the original handleClose function
  };

  return (
    <>
      <Modal
        open={open}
        aria-labelledby='parent-modal-title'
        aria-describedby='parent-modal-description'
        onClose={handleModalClose} // Use the new handleModalClose function
      >
        <Box sx={{ ...style, width: 400 }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <h2 id='parent-modal-title'>Customer Add Data</h2>
            <IconButton onClick={handleModalClose} aria-label='close'>
              <CloseIcon />
            </IconButton>
          </div>
          <TextField
            variant='outlined'
            label='Enter Data ID'
            placeholder='Type Data ID...'
            fullWidth
            value={inputValue} // Controlled input value
            onChange={(event) => setInputValue(event.target.value)} // Update input state
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                handleAddValue(inputValue);
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => handleAddValue(inputValue)} // Add value when clicking icon
                  >
                    <AddIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{ marginBottom: 2 }} // Spacing below TextField
            error={Boolean(errorMessage)} // Highlight field if there's an error
            helperText={errorMessage} // Show error message
          />
          <div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: 2 }}>
            {values.map((value, index) => (
              <Chip
                key={index}
                label={value}
                onDelete={() => handleDelete(value)}
                variant='outlined'
                sx={{ margin: 0.5 }}
              />
            ))}
          </div>
          <Button
            variant='contained'
            onClick={handleOk}
            disabled={loading || values.length === 0}
            fullWidth
          >
            {loading ? <CircularProgress size={24} /> : 'Send'}
          </Button>
        </Box>
      </Modal>
      <Snackbar
        open={Boolean(message)}
        autoHideDuration={6000}
        onClose={() => setMessage('')}
        message={message}
      />
    </>
  );
};

AttachModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  customerId: PropTypes.number.isRequired,
};

export default AttachModal;
