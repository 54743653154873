import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Grid, TextField, Button, Box, Paper, Typography} from '@mui/material';
import {DateRangePicker, LocalizationProvider} from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {enGB} from 'date-fns/locale'; // Import locale
import Autocomplete from '@mui/material/Autocomplete';

const DataSendArchiveFilterCard = ({
  domains,
  customers,
  handleCustomerChange,
  filters,
  setFilters,
  onApply,
  handleDateRangeChange,
  handleDomainChange,
  onClear,
  disableDataSource,
}) => {
  const [formattedCustomers, setFormattedCustomers] = useState([]);

  // Update formatted customers whenever the `customers` prop changes
  useEffect(() => {
    setFormattedCustomers(
      customers.map((customer) => ({
        key: customer.id.toString(),
        label: `${customer.user_name} (${customer.integration_type})`,
      })),
    );
  }, [customers]);

  useEffect(() => {
    if (disableDataSource) {
      setFilters((prev) => ({
        ...prev,
        dataSource: '',
      }));
    }
  }, [disableDataSource, setFilters]);

  // Handle the Apply Filters button click
  const handleApplyClick = () => {
    onApply(filters); // Call `onApply` with the current filter state
  };

  return (
    <Paper elevation={3} sx={{padding: 2, marginBottom: 2}}>
      <Grid container spacing={2} alignItems='center'>
        {/* Date Range Section */}
        <Grid item xs={12}>
          <Typography variant='h6' gutterBottom>
            Sent Date Range
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={enGB}>
            <DateRangePicker
              startText='From Date'
              endText='To Date'
              value={filters.dateRange || [null, null]}
              onChange={(newDateRange) => handleDateRangeChange(newDateRange)}
              renderInput={(startProps, endProps) => (
                <>
                  <TextField {...startProps} fullWidth sx={{mr: 2}} />
                  <TextField {...endProps} fullWidth />
                </>
              )}
            />
          </LocalizationProvider>
        </Grid>

        {/* Domain Dropdown Section */}
        <Grid item xs={12} sm={6} md={6}>
          <Typography variant='h6' gutterBottom>
            Domain
          </Typography>
          <Autocomplete
            disablePortal
            options={domains}
            getOptionLabel={(option) => option.label}
            value={domains.find((d) => d.key === filters.domain_id) || null}
            onChange={(event, newValue) => handleDomainChange(event, newValue)}
            renderInput={(params) => (
              <TextField {...params} label='Domain' fullWidth />
            )}
          />
        </Grid>

        {/* Customer Dropdown Section */}
        <Grid item xs={12} sm={6} md={6}>
          <Typography variant='h6' gutterBottom>
            Customer
          </Typography>
          <Autocomplete
            disablePortal
            options={formattedCustomers}
            getOptionLabel={(option) => option.label}
            value={
              formattedCustomers.find((c) => c.key === filters.customer_id) ||
              null
            }
            onChange={(event, newValue) =>
              handleCustomerChange(event, newValue)
            }
            renderInput={(params) => (
              <TextField {...params} label='Customer' fullWidth />
            )}
          />
        </Grid>

        {/* Action Buttons */}
        <Grid item xs={12}>
          <Box display='flex' justifyContent='flex-end' gap={2}>
            <Button
              variant='contained'
              color='primary'
              onClick={handleApplyClick}
            >
              Apply Filters
            </Button>
            <Button variant='outlined' color='secondary' onClick={onClear}>
              Clear Filters
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

DataSendArchiveFilterCard.propTypes = {
  domains: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  customers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      user_name: PropTypes.string.isRequired,
      integration_type: PropTypes.string,
    }),
  ).isRequired,
  handleCustomerChange: PropTypes.func.isRequired,
  filters: PropTypes.shape({
    domain_id: PropTypes.string,
    customer_id: PropTypes.string,
    dateRange: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
    dataSource: PropTypes.string,
  }).isRequired,
  setFilters: PropTypes.func.isRequired,
  onApply: PropTypes.func.isRequired,
  handleDateRangeChange: PropTypes.func.isRequired,
  handleDomainChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  disableDataSource: PropTypes.bool,
};

export default DataSendArchiveFilterCard;
