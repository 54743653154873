import React from 'react';
import {DataGrid} from '@mui/x-data-grid';
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import {format} from 'date-fns';

export default function RowEditControlGrid({
  dataToShow,
  setPageSize,
  pageSize,
  setPageNumber,
  pageNumber,
  rowCount,
  onFilterChange,
  onEditCommit,
  setPatchEdited,
  gettingData,
  handleOnSelect,
  userRole,
}) {
  const [editRowsModel, setEditRowsModel] = React.useState({});

  // Date formatting function
  const formatDate = (date) => {
    return date ? format(new Date(date), 'dd/MM/yyyy HH:mm') : '';
  };

  // Exclude customer_id and domin_id columns, but map customer_id to customer name
  const columns = dataToShow[0]
    ? Object.keys(dataToShow[0])
        .filter((key) => key !== 'Rowkey') // Hide key
        .map((key) => {
          return {
            field: key,
            headerName: key.replace(/_/g, ' ').toUpperCase(), // Format the column name for better readability
            editable: false, // Don't allow editing the ID field
            valueGetter: (params) =>
              ['created_at', 'updated_at', 'sent_at'].includes(key)
                ? formatDate(params.value)
                : params.value, // Format dates for specific fields
          };
        })
    : [];

  const handleEditRowsModelChange = React.useCallback(
    (model) => {
      setEditRowsModel(model);
      setPatchEdited(model);
    },
    [setPatchEdited],
  );

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        {userRole.includes('admin') && (
          <GridToolbarExport csvOptions={{utf8WithBom: true}} />
        )}
      </GridToolbarContainer>
    );
  }

  return (
    <>
      <div style={{width: '100%'}}>
        <DataGrid
          autoHeight
          loading={dataToShow.length === 0 && gettingData}
          checkboxSelection
          rows={dataToShow}
          getRowId={(row) => row.Rowkey} 
          onSelectionModelChange={handleOnSelect}
          rowCount={rowCount}
          columns={columns} // Apply filtered and formatted columns
          editRowsModel={editRowsModel}
          editMode='row'
          disableSelectionOnClick
          onFilterModelChange={(newFilterModel) => {
            onFilterChange(newFilterModel); // Call the filter change function
          }}
          components={{
            Toolbar: CustomToolbar,
          }}
          onEditRowsModelChange={handleEditRowsModelChange}
          page={pageNumber}
          pageSize={pageSize}
          onPageChange={setPageNumber}
          onPageSizeChange={setPageSize}
          paginationMode='server'
          rowsPerPageOptions={[10, 20, 50, 100]}
          onRowEditCommit={onEditCommit}
        />
      </div>
    </>
  );
}

RowEditControlGrid.propTypes = {
  dataToShow: PropTypes.array.isRequired,
  pageSize: PropTypes.number.isRequired,
  setPageSize: PropTypes.func.isRequired,
  pageNumber: PropTypes.number.isRequired,
  setPageNumber: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onEditCommit: PropTypes.func.isRequired,
  setPatchEdited: PropTypes.func.isRequired,
  gettingData: PropTypes.bool.isRequired,
  handleOnSelect: PropTypes.func.isRequired,
  userRole: PropTypes.array.isRequired,
  customerMapping: PropTypes.object.iequired, // Add prop types for customerMapping
};
