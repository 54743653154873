// src/pages/Reports/AddDataArchive/AddDataArchiveUI.jsx

import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Grid, Paper, Typography} from '@mui/material';
import AppComponentHeader from '../../../@crema/core/AppComponentHeader';
import AppGridContainer from '../../../@crema/core/AppGridContainer';
import DataArchiveFilterCard from 'components/FilterCard/DataArchiveFilterCard';
import FilterListIcon from '@mui/icons-material/FilterList';
import {DataGrid} from '@mui/x-data-grid';

const AddDataArchiveUI = ({
  dataArchive,
  archiveCount,
  onPageChange,
  filters,
  setFilters,
  handleDateRangeChange,
  onApply,
  onClear,
  handleGenerateReport,
}) => {
  const [isFilterCardVisible, setIsFilterCardVisible] = useState(false);

  const toggleFilterCardVisibility = () => {
    setIsFilterCardVisible(!isFilterCardVisible);
  };

  const columns = [
    {
      field: 'rowNumber',
      headerName: 'Row Number',
      width: 120,
      sortable: false,
      filterable: false,
      valueGetter: (params) => params.api.getRowIndex(params.id) + 1,
    },
    {
      field: 'name',
      headerName: 'Report Name',
      width: 250,
      flex: 1,
    },
    {
      field: 'uploaded_at',
      headerName: 'Save Date',
      width: 200,
      type: 'date',
      valueGetter: (params) => new Date(params.row.uploaded_at),
      valueFormatter: (params) => params.value.toLocaleDateString(),
    },
    {
      field: 'generateReport',
      headerName: 'Generate Report',
      width: 200,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <Button
          variant='contained'
          color='primary'
          onClick={() => handleGenerateReport(params.row.report_id)}
        >
          Generate
        </Button>
      ),
    },
  ];

  return (
    <>
      <AppComponentHeader title='Data Table' />
      <AppGridContainer>
        <Grid item xs={12}>
          <Button
            variant='contained'
            startIcon={<FilterListIcon />}
            onClick={toggleFilterCardVisibility}
          >
            {isFilterCardVisible ? 'Hide Filters' : 'Show Filters'}
          </Button>
        </Grid>

        {isFilterCardVisible && (
          <Grid item xs={12}>
            <DataArchiveFilterCard
              filters={filters}
              setFilters={setFilters}
              handleDateRangeChange={handleDateRangeChange}
              onApply={onApply}
              onClear={onClear}
              disableDataSource={false}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <Paper sx={{padding: 2, marginTop: 4}}>
            <Typography variant='h6' sx={{mb: 2}}>
              Data Archive List
            </Typography>
            <DataGrid
              rows={dataArchive}
              columns={columns}
              pageSize={10}
              paginationMode='server'
              rowCount={archiveCount}
              onPageChange={(newPage) => onPageChange(null, newPage)}
              disableSelectionOnClick
              autoHeight
            />
          </Paper>
        </Grid>
      </AppGridContainer>
    </>
  );
};

AddDataArchiveUI.propTypes = {
  dataArchive: PropTypes.array.isRequired,
  archiveCount: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
  handleDateRangeChange: PropTypes.func.isRequired,
  onApply: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  handleGenerateReport: PropTypes.func.isRequired,
};

export default AddDataArchiveUI;
