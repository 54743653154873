// src/api/sentDataToCustomersArchive.js
import jwtAxios from '@crema/services/auth/jwt-auth';
import {handleError} from './handleError';

/**
 * Fetch data for Sent Data to Customers Archive with pagination and filters
 * @param {number} page - The current page number (zero-based)
 * @param {object} filters - The filter parameters
 * @param {string} filters.domainId - ID of the selected domain
 * @param {string} filters.customerId - ID of the selected customer
 * @param {[Date, Date]} filters.dateRange - Start and end dates
 * @returns {Promise<{ data: array, count: number }>}
 */
export const getSentDataToCustomersArchive = async (page, filters) => {
  const {domain_id, customer_id, dateRange} = filters;
  const fromDate =
    dateRange && dateRange[0] ? dateRange[0].toISOString() : undefined;
  const toDate =
    dateRange && dateRange[1] ? dateRange[1].toISOString() : undefined;

  try {
    const response = await jwtAxios.get('archive/sentDataToCustomersArchive', {
      params: {
        pageNumber: page, // Assuming API uses 1-based indexing
        pageSize: 10,
        fromDate,
        toDate,
        domain_id, // Add domainId as a parameter
        customer_id, // Add customerId as a parameter
      },
    });

    console.log('Response data: ', response.data);

    const {data, dataCount} = response.data;

    return {data, count: dataCount}; // Ensure `dataCount` is being returned correctly
  } catch (error) {
    return handleError(error);
  }
};
