import React, {useState} from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import {LoadingButton} from '@mui/lab';
import ConfirmModal from './components/ConfirmModal/ConfirmModal';
import AttachModal from './components/AttachModal/AttachModal';
import DataTable from './components/DataTable';
import {AppsPagination} from '@crema';
import CustomForm from './components/CustomForm/CustomForm';
import TrackboxForm from './components/TrackboxForm/TrackBoxForm';
import DynamicFormFromURL from '../addClient/components/CustomUrlForm/DynamicFormFromURL';
import DataLG from './components/addData/AddDataGL';
// import BasicDropZone from './components/BasicDropZone'; // Import BasicDropZone

const TabPanel = ({children, value, index}) => (
  <div role='tabpanel' hidden={value !== index}>
    {value === index && <Box sx={{p: 3}}>{children}</Box>}
  </div>
);

const ClientUI = ({
  formik,
  integrationType,
  handleSwitchIntegrationType,
  handleCloseModal,
  openConfirmModal,
  description,
  isUpdateing,
  customerData,
  pageNumber,
  pageSize,
  dataCount,
  setSelectedDomain,
  setUrl,
  selectedDomain,
  setHttpMethod,
  handleDomainSelect,
  dominId,
  url,
  httpMethod,
  onPageChange,

  setAttachModal,
  openAttachModal,
  customerId,
}) => {
  const [tabIndex, setTabIndex] = useState(0);
  // const [uploadedFiles, setUploadedFiles] = useState([]); // State to manage uploaded files

  const handleTabChange = (event, newIndex) => setTabIndex(newIndex);

  // // Function to handle the "Attach" button click
  // const handleAttachFiles = async () => {
  //   if (uploadedFiles.length === 0) {
  //     alert('Please upload files before attaching.');
  //     return;
  //   }

  //   const formData = new FormData();
  //   uploadedFiles.forEach((file) => {
  //     formData.append('files', file);
  //   });

  //   try {
  //     // Replace with your API endpoint
  //     const response = await fetch('/api/customer/attach-file', {
  //       method: 'POST',
  //       body: formData,
  //     });

  //     if (!response.ok) {
  //       throw new Error('Failed to attach files');
  //     }

  //     const data = await response.json();
  //     console.log('Files attached successfully:', data);
  //     // Optionally, you can reset uploaded files state here
  //     setUploadedFiles([]);
  //     alert('Files attached successfully!');
  //   } catch (error) {
  //     console.error('Error attaching files:', error);
  //     alert('Error attaching files. Please try again.');
  //   }
  // };
  console.log('selectedDomain selectedDomain selectedDomain ');
  console.log(formik.values);
  console.log(selectedDomain);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Card>
        <CardHeader
          sx={{
            py: 3,
            pb: 1,
            px: 4,
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#f5f5f5',
            borderBottom: '1px solid #ddd',
          }}
          title={<Typography variant='h6'>Client Configuration</Typography>}
          subheader={description}
        />

        <CardContent sx={{px: 6, py: 4}}>
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            aria-label='client tabs'
            sx={{borderBottom: 1, borderColor: 'divider'}}
          >
            <Tab label='Configuration' />
            <Tab label='Data' />
            {integrationType === 'noApi' && <Tab label='File Upload' />}{' '}
            {/* Conditional tab */}
          </Tabs>

          <TabPanel value={tabIndex} index={0}>
            {/* Configuration tab content */}
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <Typography variant='body1' sx={{mb: 1}}>
                    User Name*
                  </Typography>
                  <TextField
                    id='user_name'
                    name='user_name'
                    variant='outlined'
                    onChange={formik.handleChange}
                    value={formik.values.user_name}
                    placeholder="Enter user's name"
                    required
                  />
                </FormControl>
              </Grid>

              {integrationType === 'trackbox' && (
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <Typography variant='body1' sx={{mb: 1}}>
                      Integration URL*
                    </Typography>
                    <TextField
                      id='integration_url'
                      name='integration_url'
                      variant='outlined'
                      onChange={formik.handleChange}
                      value={formik.values.integration_url}
                      placeholder='Enter integration URL'
                      required
                    />
                  </FormControl>
                </Grid>
              )}
              {/* Domain Autocomplete */}
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id='combo-box-domain'
                  options={dominId}
                  // Set the default value based on matching domain_id
                  defaultValue={selectedDomain}
                  onChange={(e, value) => {
                    handleDomainSelect(e, value);
                    setSelectedDomain(value); // Update selected domain on change
                  }}
                  sx={{width: 300}}
                  renderInput={(params) => (
                    <TextField {...params} label='Domain' />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <FormLabel sx={{fontSize: '1rem', mb: 2}}>
                    Integration Type
                  </FormLabel>
                  <RadioGroup
                    row
                    value={integrationType}
                    onChange={(e) =>
                      handleSwitchIntegrationType(e.target.value)
                    }
                  >
                    <FormControlLabel
                      value='trackbox'
                      control={<Radio />}
                      label='Trackbox'
                    />
                    <FormControlLabel
                      value='customCurl'
                      control={<Radio />}
                      label='Custom Curl'
                    />
                    <FormControlLabel
                      value='customUrl'
                      control={<Radio />}
                      label='Custom URL'
                    />
                    <FormControlLabel
                      value='noApi'
                      control={<Radio />}
                      label='No Integration'
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                {integrationType === 'customCurl' && (
                  <CustomForm formik={formik} />
                )}
                {integrationType === 'trackbox' && (
                  <TrackboxForm formik={formik} />
                )}
                {integrationType === 'customUrl' && (
                  <DynamicFormFromURL
                    formik={formik}
                    setHttpMethod={setHttpMethod}
                    httpMethod={httpMethod}
                    setUrl={setUrl}
                    url={url}
                  />
                )}
              </Grid>

              <Grid item xs={12}>
                <Box mt={2}>
                  {isUpdateing ? (
                    <LoadingButton loading variant='contained'>
                      Updating...
                    </LoadingButton>
                  ) : (
                    <Button type='submit' variant='contained' color='primary'>
                      Update Configuration
                    </Button>
                  )}
                </Box>
              </Grid>
            </Grid>
          </TabPanel>

          <TabPanel value={tabIndex} index={1}>
            {/* Data tab content */}
            <Box display='flex' justifyContent='flex-start' mb={3}>
              <Button
                variant='contained'
                color='secondary'
                onClick={() => setAttachModal(true)}
              >
                Attach Data
              </Button>
            </Box>
            {customerData && (
              <Box>
                <AppsPagination
                  rowsPerPage={pageSize}
                  count={dataCount}
                  page={pageNumber}
                  onPageChange={onPageChange}
                />
                <DataTable
                  customerData={customerData}
                  customerId={customerId}
                />
              </Box>
            )}
          </TabPanel>

          {integrationType === 'noApi' && (
            <TabPanel value={tabIndex} index={2}>
              <DataLG customerId={customerId} />
            </TabPanel>
          )}
        </CardContent>

        {/* Modals */}
        <ConfirmModal handleClose={handleCloseModal} open={openConfirmModal} />
        <AttachModal
          open={openAttachModal}
          handleClose={() => setAttachModal(false)}
          customerId={customerId}
        />
      </Card>
    </form>
  );
};

// TabPanel component for tab management
TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};

ClientUI.propTypes = {
  formik: PropTypes.object.isRequired,
  openConfirmModal: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  integrationType: PropTypes.string.isRequired,
  handleSwitchIntegrationType: PropTypes.func.isRequired,
  description: PropTypes.string,
  isUpdateing: PropTypes.bool.isRequired,
  customerData: PropTypes.array.isRequired,
  handleDomainSelect: PropTypes.func.isRequired,
  dominId: PropTypes.array.isRequired,
  selectedDomain: PropTypes.number.isRequired,
  pageNumber: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  dataCount: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  setAttachModal: PropTypes.func.isRequired,
  openAttachModal: PropTypes.bool.isRequired,
  customerId: PropTypes.number.isRequired,
  isGettingCustomerData: PropTypes.bool,
  setHttpMethod: PropTypes.func.isRequired,
  httpMethod: PropTypes.string.isRequired,
  setUrl: PropTypes.func.isRequired,
  setSelectedDomain: PropTypes.func.isRequired,

  url: PropTypes.string.isRequired,
};

export default ClientUI;
