import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from '@mui/x-data-grid';
import PropTypes from 'prop-types';

export default function RowEditControlGrid({
  dataToShow,
  setPageSize,
  pageSize,
  setPageNumber,
  pageNumber,
  rowCount,
  onFilterChange,
  onEditCommit,
  setPatchEdited,
  gettingData,
  handleOnSelect,
  userRole,
  formatDate, // Pass formatDate here
}) {
  const [editRowsModel, setEditRowsModel] = React.useState({});

  const handleEditRowsModelChange = React.useCallback((model) => {
    setEditRowsModel(model);
    setPatchEdited(model);
  }, [setPatchEdited]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        {userRole.includes('admin') && (
          <GridToolbarExport csvOptions={{ utf8WithBom: true }} />
        )}
      </GridToolbarContainer>
    );
  }

  return (
    <>
      <div style={{ width: '100%' }}>
        <div style={{ width: '100%' }}>
          <DataGrid
            autoHeight
            loading={dataToShow.length === 0 && gettingData}
            checkboxSelection
            rows={dataToShow.map((row) => ({
              ...row,
              created_at: formatDate(row.created_at),
              updated_at: formatDate(row.updated_at),
            }))}
            onSelectionModelChange={handleOnSelect}
            rowCount={rowCount}
            columns={
              dataToShow[0]
                ? Object.keys(dataToShow[0]).map((key) => ({
                    field: key,
                    headerName: key,
                    editable: key !== 'id' && key !== 'customer_id',
                  }))
                : []
            }
            onFilterModelChange={onFilterChange}
            editRowsModel={editRowsModel}
            editMode='row'
            disableSelectionOnClick
            components={{
              Toolbar: CustomToolbar,
            }}
            onEditRowsModelChange={handleEditRowsModelChange}
            page={pageNumber}
            pageSize={pageSize}
            onPageChange={setPageNumber}
            onPageSizeChange={setPageSize}
            paginationMode='server'
            rowsPerPageOptions={[10, 20, 50, 100]}
            onRowEditCommit={onEditCommit}
          />
        </div>
      </div>
    </>
  );
}

RowEditControlGrid.propTypes = {
  dataToShow: PropTypes.array.isRequired,
  pageSize: PropTypes.number.isRequired,
  setPageSize: PropTypes.func.isRequired,
  pageNumber: PropTypes.number.isRequired,
  setPageNumber: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onEditCommit: PropTypes.func.isRequired,
  setPatchEdited: PropTypes.func.isRequired,
  gettingData: PropTypes.bool.isRequired,
  handleOnSelect: PropTypes.func.isRequired,
  userRole: PropTypes.array.isRequired,
  formatDate: PropTypes.func.isRequired,
};
