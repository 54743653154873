import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import AddDataArchiveUI from './AddDataArchiveUI';
import {fetchError} from '../../../redux/actions';
import {getAddDataArchive} from '../apis/addDataArchive';
import jwtAxios from '@crema/services/auth/jwt-auth';
import {saveAs} from 'file-saver';

const AddDataArchiveContainer = () => {
  const [dataArchive, setDataArchive] = useState([]);
  const [archiveCount, setArchiveCount] = useState(0);
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState({
    dateRange: [null, null],
  });

  const dispatch = useDispatch();

  useEffect(() => {
    loadDataArchive(); // Fetch data whenever page or filters change
  }, [page, filters]);

  const loadDataArchive = async () => {
    try {
      const {data, count} = await getAddDataArchive(page, filters);
      const processedData = data.map((row) => ({
        ...row,
        id: row.report_id, // Use a unique field or create one
      }));
      setDataArchive(processedData);
      setArchiveCount(count);
    } catch (error) {
      dispatch(fetchError(error.message));
    }
  };

  const handleGenerateReport = async (report_id) => {
    try {
      const response = await jwtAxios.post(
        '/archive/export-database-archive/',
        {
          report_id: report_id,
        },
      );

      const {data, fileName} = response.data;

      const byteCharacters = atob(data);
      const byteNumbers = new Uint8Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const blob = new Blob([byteNumbers], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      saveAs(blob, fileName);
      console.log('Report downloaded successfully:', fileName);
    } catch (error) {
      dispatch(fetchError(error.message));
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleDateRangeChange = (newDateRange) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      dateRange: newDateRange,
    }));
  };

  const handleApplyFilters = () => {
    loadDataArchive(); // Re-fetch data with applied filters
  };

  const handleClearFilters = () => {
    setFilters({
      dateRange: [null, null],
    });
    setPage(0); // Reset to the first page
    loadDataArchive(); // Re-fetch data after clearing filters
  };

  return (
    <AddDataArchiveUI
      dataArchive={dataArchive}
      archiveCount={archiveCount}
      onPageChange={handlePageChange}
      filters={filters}
      setFilters={setFilters}
      handleDateRangeChange={handleDateRangeChange}
      onApply={handleApplyFilters}
      onClear={handleClearFilters}
      handleGenerateReport={handleGenerateReport}
    />
  );
};

export default AddDataArchiveContainer;
