import React from 'react';
import {RoutePermittedRole} from 'shared/constants/AppConst';
import AddDataArchiveContainer from './AddDataArchive/AddDataArchiveContainer';
import SentDataToCustomersArchiveContainer from './dataSentToCustomersReport/SentDataToCustomersArchiveContainer';

export const ReportsPagesConfigs = [
  {
    permittedRole: RoutePermittedRole.admin,
    path: '/Reports/DataSave',
    element: <AddDataArchiveContainer />,
  },
  {
    permittedRole: RoutePermittedRole.admin,
    path: '/Reports/DataSent',
    element: <SentDataToCustomersArchiveContainer />,
  },
];
