import React, {useState} from 'react';
import {
  Grid,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
} from '@mui/material';
import {Visibility, VisibilityOff} from '@mui/icons-material';
import PropTypes from 'prop-types';

const TrackboxForm = ({formik}) => {
  // State for toggling password visibility
  const [showPassword, setShowPassword] = useState(false);

  // Toggle password visibility
  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  // Set default value to an empty object if undefined
  const additionalData = formik.values.additional_data_integration || {};

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant='h6' gutterBottom>
          Trackbox Integration Details
        </Typography>
      </Grid>
      <Grid item xs={12} lg={6}>
        <TextField
          id='ai'
          name='ai'
          label='AI'
          variant='outlined'
          fullWidth
          onChange={(e) => {
            formik.setFieldValue('additional_data_integration', {
              ...additionalData,
              ai: e.target.value,
            });
          }}
          value={additionalData.ai || ''}
          required
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <TextField
          id='ci'
          name='ci'
          label='CI'
          variant='outlined'
          fullWidth
          onChange={(e) => {
            formik.setFieldValue('additional_data_integration', {
              ...additionalData,
              ci: e.target.value,
            });
          }}
          value={additionalData.ci || ''}
          required
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <TextField
          id='gi'
          name='gi'
          label='GI'
          variant='outlined'
          fullWidth
          onChange={(e) => {
            formik.setFieldValue('additional_data_integration', {
              ...additionalData,
              gi: e.target.value,
            });
          }}
          value={additionalData.gi || ''}
          required
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <TextField
          id='user_ip'
          name='user_ip'
          label='User IP'
          variant='outlined'
          fullWidth
          onChange={(e) => {
            formik.setFieldValue('additional_data_integration', {
              ...additionalData,
              user_ip: e.target.value,
            });
          }}
          value={additionalData.user_ip || ''}
          required
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <TextField
          id='trackbox_username'
          name='trackbox_username'
          label='Trackbox Username'
          variant='outlined'
          fullWidth
          onChange={(e) => {
            formik.setFieldValue('additional_data_integration', {
              ...additionalData,
              trackbox_username: e.target.value,
            });
          }}
          value={additionalData.trackbox_username || ''}
          required
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <TextField
          id='trackbox_password'
          name='trackbox_password'
          label='Trackbox Password'
          type={showPassword ? 'text' : 'password'}
          variant='outlined'
          fullWidth
          onChange={(e) => {
            formik.setFieldValue('additional_data_integration', {
              ...additionalData,
              trackbox_password: e.target.value,
            });
          }}
          value={additionalData.trackbox_password || ''}
          required
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={handleClickShowPassword}
                  edge='end'
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <TextField
          id='api_key'
          name='api_key'
          label='API Key'
          variant='outlined'
          fullWidth
          onChange={(e) => {
            formik.setFieldValue('additional_data_integration', {
              ...additionalData,
              api_key: e.target.value,
            });
          }}
          value={additionalData.api_key || ''}
          required
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <TextField
          id='sub'
          name='sub'
          label='Sub (Optional)'
          variant='outlined'
          fullWidth
          onChange={(e) => {
            formik.setFieldValue('additional_data_integration', {
              ...additionalData,
              sub: e.target.value,
            });
          }}
          value={additionalData.sub || ''}
        />
      </Grid>
    </Grid>
  );
};

TrackboxForm.propTypes = {
  formik: PropTypes.object.isRequired,
};

export default TrackboxForm;
