// src/pages/Reports/SentDataToCustomersArchive/apis/handleError.js

/**
 * Centralized error handling for API requests
 * @param {Error} error - The error thrown by axios
 * @returns {never} - Throws the error to be handled by the caller
 */
export const handleError = (error) => {
  const errorMsg =
    error.response?.data?.message ||
    error.message ||
    'An unexpected error occurred';
  console.error('API Error:', errorMsg);
  throw new Error(errorMsg);
};
