import jwtAxios, {sseRequest} from '@crema/services/auth/jwt-auth';
import UploadToClientUI from './UploadToClientUI';
import {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {AppInfoView} from '@crema';
import {fetchError, showMessage} from 'redux/actions';
import {useDispatch} from 'react-redux';

const UploadToClientLg = () => {
  const dispatch = useDispatch();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const {messages} = useIntl();
  const [domainSelected, setDomainSelected] = useState(null);
  const [domainBackendUrl, setDomainBackendUrl] = useState(null);

  // Stats for API and DB calling
  const [doneStatusCountAPI, setDoneStatusCountAPI] = useState(0);
  const [allStatusCountAPI, setAllStatusCountAPI] = useState(0);
  const [errorStatusCountAPI, setErrorStatusCountAPI] = useState(0);
  const [errorDataAPI, setErrorDataAPI] = useState([]);
  const [doneStatusCountDB, setDoneStatusCountDB] = useState(0);
  const [allStatusCountDB, setAllStatusCountDB] = useState(0);
  const [errorStatusCountDB, setErrorStatusCountDB] = useState(0);
  const [errorDataDB, setErrorDataDB] = useState([]);

  const [fileName, setFileName] = useState('');
  const [affiliateId, setAffiliateId] = useState([]);
  const [dominId, setDominId] = useState([]);
  const [customerId, setCustomerId] = useState();
  const [searchTerm, setSearchTerm] = useState('');
  const [fileSelected, setFileSelected] = useState(false);
  const [archiveData, setArchiveData] = useState([]);
  const [archiveDatabaseData, setArchiveDatabaseData] = useState([]);

  const [uploadFinished, setUploadFinished] = useState(false);
  const [domainIdSelected, setDomainIdSelected] = useState(null);

  const onDropDownChange = (_, valueSelected) => {
    if (valueSelected && valueSelected.key) {
      setCustomerId(valueSelected.key);
      setFileSelected(true);
    } else {
      setCustomerId(null);
      setFileSelected(false);
    }
  };

  const onDropDownSearch = (searchField) => {
    setSearchTerm(searchField.target.value);
  };

  // Remanage Archive Data
  const remanageDataArchive = (string) => {
    try {
      console.log('string');
      console.log(string);

      const jsonParsed = JSON.parse(string);
      const data = JSON.parse(jsonParsed.data);

      const id = data.id || 'Unknown ID';
      const status = jsonParsed.error ? jsonParsed.error : 'Success';

      console.log('Parsed Archive Data:', {id, status}); // Log parsed data

      return {id, status};
    } catch (error) {
      console.error('Error parsing archive data:', error);
      return {id: 'Unknown ID', status: 'Error'};
    }
  };
  const handleDomainChange = (_, valueSelected) => {
    if (valueSelected && valueSelected.key) {
      setDomainSelected(valueSelected.key);
      const selectedDomain = dominId.find(
        (domain) => domain.key === valueSelected.key,
      );
      if (selectedDomain) {
        setDomainBackendUrl(selectedDomain.url);
        setDomainIdSelected(selectedDomain.key);
      }
      setCustomerId(valueSelected.key);
      getCustomers(10, 0, searchTerm, valueSelected.key);
      onDropDownChange(_, valueSelected);
    } else {
      setDomainSelected(null);
      setDomainBackendUrl(null);
      setCustomerId(null);
      setAffiliateId([]);
      onDropDownChange(_, valueSelected);
    }
  };

  const sendFile = async () => {
    if (!uploadedFiles || uploadedFiles.length !== 1) {
      dispatch(fetchError('Please select only one file'));
      return;
    }

    const fileExtension = uploadedFiles[0].name.split('.').pop();
    setFileName(uploadedFiles[0].name.split('.')[0]);

    const validExtensions = [
      'xlsx',
      'xlsm',
      'xlsb',
      'xltx',
      'xltm',
      'xls',
      'xlt',
      'xml',
      'xlam',
      'xla',
      'xlw',
      'xlr',
    ];
    if (!validExtensions.includes(fileExtension)) {
      dispatch(fetchError('This file type is not supported'));
      return;
    }

    const formData = new FormData();
    formData.append('myfile', uploadedFiles[0]);

    setIsLoading(true);
    setIsClicked(true);
    // Use the base URL from the jwtAxios instance
    const newBaseUrl = domainBackendUrl || jwtAxios.defaults.baseURL;
    sseRequest(`data/upload-file/send-data/${customerId}`, {
      body: formData,
      baseUrl: newBaseUrl,
      onmessage: (message) => {
        if (message.event.includes('doneUploadData')) {
          setDoneStatusCountDB((prev) => prev + 1);
          setAllStatusCountDB((prev) => prev + 1);
          setArchiveDatabaseData((prev) => {
            const {id, status} = remanageDataArchive(message.data);
            return {...prev, [id]: {id, status}};
          });
        } else if (message.event.includes('errorUploadData')) {
          setErrorStatusCountDB((prev) => prev + 1);
          setErrorDataDB((prev) => [...prev, remanageDataError(message.data)]);
          setAllStatusCountDB((prev) => prev + 1);
          setArchiveDatabaseData((prev) => {
            console.log(message.data);
            
            const {id, status} = remanageDataArchive(message.data);
            return {...prev, [id]: {id, status}};
          });
        }

        if (message.event.includes('doneUploadAPI')) {
          setDoneStatusCountAPI((prev) => prev + 1);
          setAllStatusCountAPI((prev) => prev + 1);
          setArchiveData((prev) => {
            const {id, status} = remanageDataArchive(message.data);
            return {...prev, [id]: {id, status}};
          });
          
        } else if (message.event.includes('errorUploadAPI')) {
          setErrorStatusCountAPI((prev) => prev + 1);
          setErrorDataAPI((prev) => [...prev, remanageDataError(message.data)]);
          setAllStatusCountAPI((prev) => prev + 1);
          setArchiveData((prev) => {
            const {id, status} = remanageDataArchive(message.data);
            return {...prev, [id]: {id, status}};
          });
      
        }
        console.log('message.data');
        console.log(message.data);
      },
      onclose: () => {
        setIsLoading(false);
        setUploadFinished(true);
      },
      onerror: (error) => {
        dispatch(fetchError('Failed: ' + error?.message));
        setIsLoading(false);
      },
    });
  };

  const remanageDataError = (string) => {
    const jsonParsed = JSON.parse(string);
    const data = JSON.parse(jsonParsed.data);
    data.error = jsonParsed.error;
    return data;
  };

  const archiveDataCall = () => {
    const archiveEndpoint = '/archive/add-api';
    // Map archiveData to the required format with "id" and "status"
    const mappedArchiveData = Object.values(archiveData).map((entry) => ({
      id: entry.id,
      status: entry.status,
    }));

    const archivePayload = {
      archive: mappedArchiveData,
      customer_id: customerId,
      domain_id: domainIdSelected,
    };

    jwtAxios
      .post(archiveEndpoint, archivePayload)
      .then(() => {
        dispatch(showMessage('Data archived successfully'));
        setArchiveData([]);
      })
      .catch((error) => {
        dispatch(fetchError('Failed to archive data: ' + error.message));
      });
  };

  const archiveDataDatabaseCall = () => {
    const archiveEndpoint = '/archive/add-dataBase-Archive';
    // Map archiveData to the required format with "id" and "status"
    const mappedArchiveData = Object.values(archiveDatabaseData).map(
      (entry) => ({
        id: entry.id,
        status: entry.status,
      }),
    );

    const archivePayload = {
      archive: mappedArchiveData,
      name: fileName,
    };

    jwtAxios
      .post(archiveEndpoint, archivePayload)
      .then(() => {
        dispatch(showMessage('Data archived successfully'));
        setArchiveData([]);
      })
      .catch((error) => {
        dispatch(fetchError('Failed to archive data: ' + error.message));
      });
  };

  useEffect(() => {
    if (uploadFinished) {
      archiveDataCall();
      archiveDataDatabaseCall();
      setUploadFinished(false);
    }
  }, [uploadFinished]);

  const getDomins = (pageSize, pageNumber, search) => {
    const url = `domain/search?pageSize=${pageSize}&pageNumber=${pageNumber}&term=${search}`;
    jwtAxios
      .get(url)
      .then((e) => {
        setDominId(
          e.data.data.map((item) => ({
            label: `${item.domain_name}`,
            key: item.id,
            url: item.domain_backend_url,
          })),
        );
      })
      .catch((e) => {
        dispatch(fetchError(e.message));
      });
  };

  const getCustomers = (pageSize, pageNumber, search, domainId) => {
    const url = `customer/search-with-ingration-only?pageSize=${pageSize}&pageNumber=${pageNumber}&term=${search}&domainId=${domainId}`;
    jwtAxios
      .get(url)
      .then((e) => {
        setAffiliateId(
          e.data.data.data.map((item) => ({
            label: `${item.user_name}${
              item.integration_type === 'trackbox'
                ? ' - ' + JSON.parse(item.additional_data_integration)?.ai
                : ''
            }`,
            key: item.id,
          })),
        );
      })
      .catch((e) => {
        dispatch(fetchError(e.message));
      });
  };

  useEffect(() => {
    const getData = setTimeout(() => {
      getDomins(10, 0, searchTerm);
    }, 1000);
    return () => clearTimeout(getData);
  }, []);

  return (
    <>
      <AppInfoView />
      <UploadToClientUI
        fileName={fileName}
        isClicked={isClicked}
        sendDataLoading={isLoading}
        setUploadedFiles={setUploadedFiles}
        handleDomainChange={handleDomainChange}
        uploadedFiles={uploadedFiles}
        functionToRun={sendFile}
        domainSelected={domainSelected}
        messages={messages}
        allStatusCountDB={allStatusCountDB}
        doneStatusCountDB={doneStatusCountDB}
        errorStatusCountDB={errorStatusCountDB}
        errorDataDB={errorDataDB}
        allStatusCountAPI={allStatusCountAPI}
        doneStatusCountAPI={doneStatusCountAPI}
        errorStatusCountAPI={errorStatusCountAPI}
        errorDataAPI={errorDataAPI}
        affiliateId={affiliateId}
        dominId={dominId}
        onDropDownChange={onDropDownChange}
        fileSelected={fileSelected}
        onDropDownSearch={onDropDownSearch}
      />
    </>
  );
};

export default UploadToClientLg;
