import {useEffect} from 'react';
import ClientUI from './ClientUI';
import {useState} from 'react';
import jwtAxios from '@crema/services/auth/jwt-auth';
import {useDispatch} from 'react-redux';
import {fetchError} from 'redux/actions';
import {useParams} from 'react-router-dom';
import {AppInfoView} from '@crema';
import {useFormik} from 'formik';
import {isHttpValid} from 'utils';

const ClientGL = () => {
  const dispatcher = useDispatch();
  const {id} = useParams();
  const [customer, setCustomer] = useState();
  const [integrationType, setIntegrationType] = useState('');
  const [isGettingCustomer, setIsGettingCustomer] = useState(false);
  const [isGettingCustomerData, setIsGettingCustomerData] = useState(false);
  const [isUpdateing, setIsUpdateing] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [customerData, setCustomerData] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [urlCode, setUrl] = useState('');
  const [httpMethod, setHttpMethod] = useState('');
  const [pageSize] = useState(10);
  const [openAttachModal, setAttachModal] = useState(false);
  const [dominId, setDominId] = useState([]);
  const [selectedDomain, setSelectedDomain] = useState(null);
  const dispatch = useDispatch();

  const formik = useFormik({
    onSubmit: (values) => {
      let domain_id =
        integrationType === 'noApi' ? 1 : selectedDomain?.key || null;

      if (
        (integrationType == 'trackbox' &&
          !isHttpValid(values.integration_url)) ||
        (integrationType == 'noApi' && !isHttpValid(values.integration_url))
      ) {
        dispatcher(fetchError('url not valid'));
        return;
      }
      let customUrlObject = {};
      if (integrationType == 'customUrl') {
        customUrlObject = {
          url: urlCode,
          method: httpMethod,
        };
      }
      let requestBody =
        integrationType == 'customUrl'
          ? {
              ...values,
              additional_data_integration: {...customUrlObject},
              integration_type: integrationType,
              domain_id: domain_id, // Add domain_id here
            }
          : {
              ...values,
              integration_type: integrationType,
              domain_id: domain_id, // Add domain_id here
            };

      setIsUpdateing(true);
      jwtAxios
        .put(`customer/${id}`, requestBody)
        .then(() => {
          setOpenConfirmModal(true);
        })
        .catch((e) => {
          dispatcher(fetchError(e.message));
        })
        .finally(() => {
          setIsUpdateing(false);
        });
    },
  });

  const getDomins = (pageSize, pageNumber) => {
    const url = `domain/search?pageSize=${pageSize}&pageNumber=${pageNumber}&term=`;
    jwtAxios
      .get(url)
      .then((e) => {
        setDominId(
          e.data.data.map((item) => {
            console.log(item);
            return {
              label: `${item.domain_name}`,
              key: item.id,
            };
          }),
        );
      })
      .catch((e) => {
        dispatch(fetchError(e.message));
      });
    console.log('finish fetch');
  };
  const getCustomerData = async () => {
    setIsGettingCustomerData(true);
    await jwtAxios
      .get(`data/customer/${id}?pageSize=${pageSize}&pageNumber=${pageNumber}`)
      .then((res) => {
        setCustomerData(res.data.data.customer_data);

        setDataCount(res.data.data.data_count);
      })
      .catch((e) => {
        dispatcher(fetchError(e.message));
      })
      .finally(() => {
        setIsGettingCustomerData(false);
      });
  };

  const getCustomer = async () => {
    setIsGettingCustomer(true);
    await jwtAxios
      .get(`customer/${id}`)
      .then((res) => {
        const fetchedCustomer = res.data.data.customer;
        setCustomer(fetchedCustomer);

        // Set formik values with fetched customer data
        formik.setValues({
          ...fetchedCustomer,
        });

        setIntegrationType(fetchedCustomer.integration_type);

        if (fetchedCustomer.integration_type === 'customUrl') {
          setUrl(fetchedCustomer.additional_data_integration.url);
          setHttpMethod(fetchedCustomer.additional_data_integration.method);
        }
        console.log(dominId);
        console.log(fetchedCustomer);

        // Set the selected domain based on customer.domain_id
        // Set the selected domain based on customer.domain_id
        const selectedDomain = dominId.find((domain) => {
          console.log('domain', domain); // Log the domain object inside find

          // Return the comparison result
          return domain.key === fetchedCustomer.domain_id;
        });
        if (selectedDomain) {
          setSelectedDomain(selectedDomain); // Set the selected domain
        }
      })
      .catch((e) => {
        dispatcher(fetchError(e.message));
      })
      .finally(() => {
        setIsGettingCustomer(false);
      });
  };

  // Fetch domains on component mount
  useEffect(() => {
    const fetchDomains = async () => {
      await getDomins(10, 0);
    };
    fetchDomains();
  }, []);

  // Fetch customer data once domains are fetched
  useEffect(() => {
    if (dominId.length > 0) {
      console.log('Starting to fetch customer data.');
      getCustomer();
    }
  }, [dominId]);

  useEffect(() => {
    getCustomerData();
  }, [pageNumber]);

  const handleSwitchIntegrationType = (e) => {
    setIntegrationType(e);
  };

  const handleCloseModal = () => {
    setOpenConfirmModal(true);
  };
  const onPageChange = (event, value) => {
    setPageNumber(value);
  };
  const handleDomainSelect = (event, value) => {
    setSelectedDomain(value);
    console.log('selectedDomain');

    console.log(selectedDomain);
  };

  return (
    <>
      <AppInfoView />
      {console.log(customer)}
      {customer && !isGettingCustomer && (
        <ClientUI
          formik={formik}
          integrationType={integrationType}
          handleSwitchIntegrationType={handleSwitchIntegrationType}
          openConfirmModal={openConfirmModal}
          handleCloseModal={handleCloseModal}
          isUpdateing={isUpdateing}
          customerData={customerData}
          pageNumber={pageNumber}
          pageSize={pageSize}
          setSelectedDomain={setSelectedDomain}
          setUrl={setUrl}
          url={urlCode}
          dominId={dominId}
          selectedDomain={selectedDomain}
          httpMethod={httpMethod}
          handleDomainSelect={handleDomainSelect}
          setHttpMethod={setHttpMethod}
          dataCount={dataCount}
          onPageChange={onPageChange}
          setAttachModal={setAttachModal}
          openAttachModal={openAttachModal}
          customerId={customer?.id}
          isGettingCustomerData={isGettingCustomerData}
        />
      )}
    </>
  );
};

export default ClientGL;
