import {Box, Button, Modal, Typography} from '@mui/material';
import PropTypes from 'prop-types';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 4,
  p: 4,
};

const ExportModal = ({
  exportingModal,
  exportingModalClose,
  exportDataToExcle,
}) => {
  return (
    <Modal
      open={exportingModal}
      onClose={exportingModalClose}
      aria-labelledby='export-modal-title'
      aria-describedby='export-modal-description'
    >
      <Box sx={style}>
        <Typography id='export-modal-title' variant='h6' component='h2'>
          Confirm Export
        </Typography>
        <Typography id='export-modal-description' sx={{mt: 2}}>
          Are you sure you want to export the data?
        </Typography>
        <Box sx={{mt: 3, display: 'flex', justifyContent: 'space-between'}}>
          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              exportDataToExcle();
              exportingModalClose();
            }}
          >
            Export
          </Button>
          <Button variant='outlined' onClick={exportingModalClose}>
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

ExportModal.propTypes = {
  exportingModal: PropTypes.bool.isRequired,
  exportingModalClose: PropTypes.func.isRequired,
  exportDataToExcle: PropTypes.func.isRequired,
};

export default ExportModal;
