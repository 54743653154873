// src/components/FilterCard/DataArchiveFilterCard.jsx

import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Grid, TextField, Button, Box, Paper, Typography} from '@mui/material';
import {DateRangePicker, LocalizationProvider} from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns'; // Ensure you're using DateFns

const DataArchiveFilterCard = ({
  filters,
  setFilters,
  onApply,
  handleDateRangeChange,
  onClear,
  disableDataSource,
}) => {
  // Effect to handle disabling Data Source
  useEffect(() => {
    if (disableDataSource) {
      setFilters((prev) => ({
        ...prev,
        dataSource: '',
      }));
    }
  }, [disableDataSource, setFilters]);

  return (
    <Paper elevation={3} sx={{padding: 2, marginBottom: 2}}>
      <Grid container spacing={2} alignItems='center'>
        {/* Date Range Section */}
        <Grid item xs={12}>
          <Typography variant='h6' gutterBottom>
            Save Date Range
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateRangePicker
              startText='From Date'
              endText='To Date'
              value={filters.dateRange || [null, null]}
              onChange={handleDateRangeChange}
              renderInput={(startProps, endProps) => (
                <>
                  <TextField {...startProps} fullWidth sx={{mr: 2}} />
                  <TextField {...endProps} fullWidth />
                </>
              )}
            />
          </LocalizationProvider>
        </Grid>

        {/* Action Buttons */}
        <Grid item xs={12}>
          <Box display='flex' justifyContent='flex-end' gap={2}>
            <Button variant='contained' color='primary' onClick={onApply}>
              Apply Filters
            </Button>
            <Button variant='outlined' color='secondary' onClick={onClear}>
              Clear Filters
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

// Corrected PropTypes to reflect actual props
DataArchiveFilterCard.propTypes = {
  filters: PropTypes.shape({
    dropdown1: PropTypes.string,
    dropdown2: PropTypes.string,
    domain: PropTypes.string,
    customer: PropTypes.string,
    dateRange: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
    dataSource: PropTypes.string,
  }).isRequired,
  setFilters: PropTypes.func.isRequired,
  onApply: PropTypes.func.isRequired,
  handleDateRangeChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  disableDataSource: PropTypes.bool,
};

export default DataArchiveFilterCard;
