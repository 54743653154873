// src/api/addDataArchive.js
import jwtAxios from '@crema/services/auth/jwt-auth';
import {handleError} from './handleError';

/**
 * Fetch data for Add Data Archive with pagination and filters
 * @param {number} page - The current page number (zero-based)
 * @param {object} filters - The filter parameters
 * @param {string} filters.dropdown1 - Value for Dropdown 1
 * @param {string} filters.dropdown2 - Value for Dropdown 2
 * @param {[Date, Date]} filters.dateRange - Start and end dates
 * @returns {Promise<{ data: array, count: number }>}
 */
export const getAddDataArchive = async (page, filters) => {
  const {dropdown1, dropdown2, dateRange} = filters;
  const fromDate =
    dateRange && dateRange[0] ? dateRange[0].toISOString() : undefined;
  const toDate =
    dateRange && dateRange[1] ? dateRange[1].toISOString() : undefined;

  try {
    const response = await jwtAxios.get('archive/filtered-database-archive', {
      params: {
        pageNumber: page, // Assuming API uses 1-based indexing
        pageSize: 10,
        dropdown1,
        dropdown2,
        fromDate,
        toDate,
      },
    });
    console.log('response.data');

    console.log(response.data);

    const {data} = response.data;
    const {totalCount} = response.data;
    console.log('datadatadatadatadata');
    console.log(data);

    return {data, count: totalCount};
  } catch (error) {
    return handleError(error);
  }
};
