// src/pages/data/dataW/DataLG.jsx

import React, {useEffect, useState} from 'react';
import jwtAxios, {sseRequest} from '@crema/services/auth/jwt-auth';
import DataUI from './DataUI';
import {AppInfoView} from '@crema';
import {useDispatch} from 'react-redux';
import {fetchError, showMessage} from 'redux/actions';
import {useAuthUser} from '@crema/utility/AuthHooks';
import {saveAs} from 'file-saver';

const DataLG = () => {
  const dispatch = useDispatch();

  // Pagination and Data State
  const [rowCount, setRowCount] = useState(10);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [dataToShow, setDataToShow] = useState([]);
  const [customerMapping, setCustomerMapping] = useState({});
  const [exportingModal, setExportingModal] = useState(false);

  // Filter State
  const [filters, setFilters] = useState({
    country: '',
    dataSource: '',
    domain: '',
    customer: '',
    search: '',
    dateRange: [null, null],
  });

  // Loading and Error States
  const [gettingData, setGettingData] = useState(false);
  const [exportingData, setExportingData] = useState(false);

  const [selected, setSelected] = useState([]);
  const [doneStatusCount, setDoneStatusCount] = useState(0);
  const [allStatusCount, setAllStatusCount] = useState(0);
  const [errorStatusCount, setErrorStatusCount] = useState(0);

  // Modal States
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [upLoadingData, setUpLoadingData] = useState(false);

  // Search and Selection States
  const [searchTerm, setSearchTerm] = useState('');
  const [customerId, setCustomerId] = useState(0);
  const [selecteCustomer, setSelecteCustomer] = useState(false);
  const [affiliateId, setAffiliateId] = useState([]);
  const [affiliateIdWithoutNoApi, setAffiliateIdWithoutNoApi] = useState([]);

  // Error Handling
  const [errorData, setErrorData] = useState([]);

  // Domain States
  const [dominId, setDominId] = useState([]);
  const [domainSelected, setDomainSelected] = useState(false);
  const [domainBackendUrl, setDomainBackendUrl] = useState(null);

  // New Filter Options States
  const [scorcessOptions, setScorcessOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);

  // Patch Edited State
  const [patchEdited, setPatchEdited] = useState({});

  const user = useAuthUser();
  const [isFilterCardVisible, setIsFilterCardVisible] = useState(false); // default is visible
  const [archiveData, setArchiveData] = useState({});
  const [uploadFinished, setUploadFinished] = useState(false); // New state to track upload completion
  const [domainIdSelected, setDomainIdSelected] = useState(null);

  // New State for disableDataSource Flag
  const [disableDataSource, setDisableDataSource] = useState(false);

  useEffect(() => {
    getData(pageNumber, pageSize);
    getFilterOptions(); // Fetch filter options on mount
    getCustomers(20, 0, searchTerm); // No domainId on initial load
  }, [pageSize, pageNumber]);
  useEffect(() => {
    getCustomersForSend(20, 0, searchTerm);
  }, [showUploadModal]);

  // Fetch domains when the component mounts
  useEffect(() => {
    const getDataTimeout = setTimeout(() => {
      console.log('Start fetching domains');
      getDomains(10, 0, searchTerm);
    }, 1000);
    return () => clearTimeout(getDataTimeout);
  }, []);
  // Toggle the visibility of the filter card
  const toggleFilterCardVisibility = () => {
    setIsFilterCardVisible(!isFilterCardVisible);
  };

  // Fetch Data with Filters
  const getData = (pageNumber, pageSize, filterParams = filters) => {
    console.log(filterParams);

    setGettingData(true);
    setDisableDataSource(false);
    // Construct query string based on filter parameters
    let query = `data/all?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    if (filterParams.customer) query += `&customer=${filterParams.customer}`;
    if (filterParams.country) query += `&country=${filterParams.country}`;
    if (filterParams.domain) query += `&domainId=${filterParams.domain}`;
    if (filterParams.dataSource)
      query += `&dataSource=${filterParams.dataSource}`;
    if (filterParams.search)
      query += `&searchParamsData=${filterParams.search}`;
    if (filterParams.dateRange) {
      const [startDate, endDate] = filterParams.dateRange;
      if (startDate && endDate) {
        query += `&fromDate=${startDate}&toDate=${endDate}`;
      }
    }
    jwtAxios
      .get(query)
      .then((result) => {
        setDataToShow(result.data.data.data);
        setRowCount(result.data.data.count);
      })
      .catch((e) => {
        dispatch(fetchError(e.message));
      })
      .finally(() => {
        setGettingData(false);
      });
  };
  // Fetch filter options from the backend
  // Fetch filter options from the backend
  const getFilterOptions = () => {
    jwtAxios
      .get('data/filterOptions') // Replace with your actual endpoint
      .then((response) => {
        // Clean up response data for valid country options and data sources
        const scorcessSet = new Set(response.data.data.dataSources);
        const countriesSet = new Set(response.data.data.countries);
        console.log('countriesSet');
        console.log(countriesSet);
        console.log('scorcessSet');
        console.log(scorcessSet);

        setScorcessOptions(
          [...scorcessSet].map((dataSource) => ({
            label: dataSource,
            key: dataSource,
          })),
        );

        setCountryOptions(
          [...countriesSet].map((country) => ({
            label: country,
            key: country,
          })),
        );
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
      });
  };

  // useEffect to send mapped archiveData to the API
  useEffect(() => {
    if (uploadFinished) {
      console.log('Final archiveData:', archiveData); // Log final archiveData

      // Define the API endpoint using backticks for template literals
      const apiEndpoint = `/archive/add-api`;
      console.log('Sending archiveData to:', apiEndpoint);

      // Map archiveData to the required format with "id" and "status"
      const mappedArchiveData = Object.values(archiveData).map((entry) => ({
        id: entry.id,
        status: entry.status,
      }));

      // Prepare the data to send (ensure it matches the API's expected format)
      const dataToSend = {
        archive: mappedArchiveData, // Adjusted archive structure
        customer_id: customerId,
        domain_id: domainIdSelected,
      };
      console.log('Data to send:', dataToSend);

      // Make the POST request using jwtAxios
      jwtAxios
        .post(apiEndpoint, dataToSend)
        .then((response) => {
          console.log('API response:', response);

          // Handle successful response
          dispatch(showMessage('Archive data successfully sent.'));
          // Reset archiveData and uploadFinished
          setArchiveData({});
          setUploadFinished(false);
        })
        .catch((error) => {
          console.log('API error:', error);
          // Handle errors
          dispatch(fetchError('Failed to send archive data: ' + error.message));
        });
    }
  }, [uploadFinished, dispatch]);

  // Handle Applying Filters
  const handleApplyFilters = () => {
    // Construct filter parameters based on selected filters
    const params = {
      country: filters.country,
      dataSource: filters.dataSource,
      search: filters.search,
      domain: filters.domain, // Include domain
      customer: filters.customer, // Include customer
      dateRange: filters.dateRange,
    };

    setPageNumber(0); // Reset to first page on filter

    // Fetch data with applied filters
    getData(0, pageSize, params);
  };
  const exportingModalOpen = () => {
    setExportingModal(true);
  };

  const exportingModalClose = () => {
    setExportingModal(false);
  };
  // Remanage Archive Data
  const remanageDataArchive = (string) => {
    try {
      console.log('string');
      console.log(string);

      const jsonParsed = JSON.parse(string);
      const data = JSON.parse(jsonParsed.data);

      const id = data.id || 'Unknown ID';
      const status = jsonParsed.error ? jsonParsed.error : 'Success';

      console.log('Parsed Archive Data:', {id, status}); // Log parsed data

      return {id, status};
    } catch (error) {
      console.error('Error parsing archive data:', error);
      return {id: 'Unknown ID', status: 'Error'};
    }
  };
  // Handle Customer Selection Change
  const handleCustomerChange = (_, valueSelected) => {
    if (valueSelected && valueSelected.key) {
      setFilters((prev) => ({
        ...prev,
        customer: valueSelected.key, // Set customer in filters
      }));
      handleApplyFilters(); // Apply the filters after the change
    } else {
      setFilters((prev) => ({
        ...prev,
        customer: '', // Clear the customer field if selection is cleared
      }));
      handleApplyFilters(); // Apply the filters after the change
    }
  };

  // Handle Clearing Filters
  const handleClearFilters = () => {
    setFilters({
      country: '',
      dataSource: '',
      search: '',
      dateRange: [null, null],
    });
    setPageNumber(0);
    getData(0, pageSize); // Fetch data without filters
  };

  // Handle Modal Close
  // Handle Modal Close
  const handleCloseModal = () => {
    setShowUploadModal(false);
    setSelecteCustomer(false);
    setDomainSelected(false);
    setDomainIdSelected(null);

    // Only clear archiveData if not uploading
    if (!upLoadingData) {
      setArchiveData({});
    }
  };

  // Handle Domain Selection Change
  const handleDomainChange = (_, valueSelected) => {
    if (valueSelected && valueSelected.key) {
      setDomainSelected(true);
      const selectedDomain = dominId.find(
        (domain) => domain.key === valueSelected.key,
      );
      if (selectedDomain) {
        setDomainBackendUrl(selectedDomain.url); // Store the selected domain's backend URL
        console.log(selectedDomain.url); // Log the URL to silence the warning
        setDomainIdSelected(selectedDomain.key);
      }
      setCustomerId(null); // Clear customer ID
      getCustomers(10, 0, searchTerm, valueSelected.key); // Fetch customers with the selected domain ID
      setFilters((prev) => ({...prev, domain: valueSelected.key})); // Update domain in filters
      handleApplyFilters(); // Automatically apply filters
    } else {
      setDomainSelected(false);
      setDomainIdSelected(null);
      setDomainBackendUrl(null); // Clear the backend URL
      setCustomerId(null); // Clear the customer ID
      setAffiliateId([]); // Clear customer list if no domain is selected
      onDropDownChange(_, valueSelected);
      setSelecteCustomer(false); // Hide AI dropdown
    }
  };

  // Handle other dropdowns (generic handler)
  const handleChange = (field) => (event, value) => {
    setFilters((prev) => ({
      ...prev,
      [field]: value?.key || '',
    }));
  };

  // Handle Editing Data
  const onEditCommit = (rowId) => {
    const flatingObject = {};
    const neededObject = patchEdited[rowId];
    Object.keys(neededObject).forEach((key) => {
      flatingObject[key] = neededObject[key].value;
    });
    if (rowId !== flatingObject.id) {
      dispatch(fetchError('You cannot update ID'));
      return;
    }
    jwtAxios
      .put(`data`, flatingObject)
      .then(() => {
        dispatch(
          showMessage(
            'Successfully updated data with ID = ' + flatingObject.id,
          ),
        );
      })
      .catch((e) => {
        dispatch(fetchError(e.message));
      });
  };

  // Handle Row Selection
  const handleOnSelect = (values) => {
    // Assuming 'values' is an array of strings, you can map over it and split on '-'
    const modifiedValues = values.map((value) =>
      Number(value.split('-')[0].trim()),
    ); // Split on '-' and take the first part, then trim spaces

    setSelected(modifiedValues);
  };

  // Handle Upload Submission
  const onUploadSubmitting = () => {
    setUpLoadingData(true);

    // Use the selected domain's backend URL
    const baseUrl = domainBackendUrl || jwtAxios.defaults.baseURL;

    sseRequest(`data/send-data/${customerId}`, {
      contentType: 'application/json',
      body: JSON.stringify({dataIds: selected}),
      baseUrl: baseUrl, // Pass the backend URL here
      onmessage: (message) => {
        if (message.event.includes('doneUploadAPI')) {
          setAllStatusCount((prev) => prev + 1);
          setDoneStatusCount((prev) => prev + 1);
        } else if (message.event.includes('errorUploadAPI')) {
          setErrorStatusCount((prev) => prev + 1);
          setAllStatusCount((prev) => prev + 1);
          setErrorData((prev) => {
            prev.push(remanageDataError(message.data));
            return [...prev];
          });
        }
        // Update archiveData by accumulating all id-status pairs
        setArchiveData((prev) => {
          const {id, status} = remanageDataArchive(message.data);
          return {...prev, [id]: {id, status}};
        });
      },
      onclose: () => {
        setUpLoadingData(false);
        setUploadFinished(true); // Set uploadFinished to true
      },
      onerror: (error) => {
        dispatch(fetchError('Failed: ' + error?.message));
        setUpLoadingData(false);
      },
    });
  };

  // Fetch Domains
  const getDomains = (pageSize, pageNumber, search) => {
    const url = `domain/search?pageSize=${pageSize}&pageNumber=${pageNumber}&term=${search}`;
    jwtAxios
      .get(url)
      .then((e) => {
        setDominId(
          e.data.data.map((item) => {
            console.log(item);
            return {
              label: `${item.domain_name}`,
              key: item.id,
              url: item.domain_backend_url, // Store the domain's backend URL
            };
          }),
        );
      })
      .catch((e) => {
        dispatch(fetchError(e.message));
      });
  };

  // Handle Data Deletion
  const deleteData = () => {
    setUpLoadingData(true);
    jwtAxios
      .post(`data/delete-all`, {ids: selected})
      .then(() => getData(pageNumber, pageSize))
      .catch((e) => dispatch(fetchError(e.message)));
  };

  // Remanage Error Data
  const remanageDataError = (string) => {
    const jsonParsed = JSON.parse(string);
    const data = JSON.parse(jsonParsed.data);
    data.error = jsonParsed.error;
    return data;
  };

  // Fetch Customers
  const getCustomers = (pageSize, pageNumber, search, dominId = null) => {
    const url = `customer/search-with-ingration-only?pageSize=${pageSize}&pageNumber=${pageNumber}&term=${search}${
      dominId ? `&domainId=${dominId}` : ''
    }`;

    jwtAxios
      .get(url)
      .then((e) => {
        const mapping = {};
        const customers = e.data.data.data.map((item) => {
          const customerName = `${item.user_name}${
            item.integration_type === 'trackbox'
              ? ' - ' + JSON.parse(item.additional_data_integration)?.ai
              : ''
          }`;
          console.log('customerName');
          console.log(customerName);

          mapping[item.id] = customerName;
          return {
            label: customerName,
            key: item.id,
          };
        });
        setAffiliateId(customers);

        setCustomerMapping(mapping); // Store the mapping of customer_id to customer_name
      })
      .catch((e) => {
        dispatch(fetchError(e.message));
      });
  };
  // Fetch Customers
  const getCustomersForSend = (
    pageSize,
    pageNumber,
    search,
    dominId = null,
  ) => {
    const url = `customer/search?pageSize=${pageSize}&pageNumber=${pageNumber}&term=${search}&${
      dominId ? `&domainId=${dominId}` : ''
    }`;

    jwtAxios
      .get(url)
      .then((e) => {
        const mapping = {};
        const customers = e.data.data.data.map((item) => {
          const customerName = `${item.user_name}${
            item.integration_type === 'trackbox'
              ? ' - ' + JSON.parse(item.additional_data_integration)?.ai
              : ''
          }`;
          console.log('customerName');
          console.log(customerName);

          mapping[item.id] = customerName;
          return {
            label: customerName,
            key: item.id,
          };
        });

        setAffiliateIdWithoutNoApi(customers);
        setCustomerMapping(mapping); // Store the mapping of customer_id to customer_name
      })
      .catch((e) => {
        dispatch(fetchError(e.message));
      });
  };
  // Handler for search input changes
  const handleSearchChange = (event) => {
    setFilters((prev) => ({
      ...prev,
      search: event.target.value,
    }));
  };

  // Handler for Date Range change
  const handleDateRangeChange = (newValue) => {
    setFilters((prev) => ({
      ...prev,
      dateRange: newValue,
    }));
  };

  // Handle Customer Dropdown Change
  const onDropDownChange = (_, valueSelected) => {
    if (valueSelected && valueSelected.key) {
      setCustomerId(valueSelected.key);
      setSelecteCustomer(true);
    } else {
      // Handle the case when the value is cleared (valueSelected is null or undefined)
      setCustomerId(null);
      setSelecteCustomer(false); // or any other default or fallback behavior you want when the value is cleared
    }
  };

  // Handle Dropdown Search
  const onDropDownSearch = (searchField) => {
    setSearchTerm(searchField.target.value);
  };

  // Handle Clearing Upload Modal Status
  const handleClear = () => {
    setErrorStatusCount(0);
    setAllStatusCount(0);
    setErrorData([]);
    setDoneStatusCount(0);
  };

  const handleGenerateReport = async (reportId) => {
    try {
      const response = await exportDataToExcle(reportId); // This should return the response object

      // Since the response is already parsed, directly destructure the needed values
      const {data, fileName} = response; // Get JSON data directly

      // Create a Blob from the Base64 string
      const byteCharacters = atob(data); // Decode the Base64 string
      const byteNumbers = new Uint8Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const blob = new Blob([byteNumbers], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      // Use FileSaver to save the blob as a file
      saveAs(blob, fileName);
      console.log('Report downloaded successfully:', fileName);
    } catch (error) {
      console.error('Error generating report:', error);
      dispatch(fetchError(error.message)); // Display error message in the UI
    }
  };

  const exportDataToExcle = async () => {
    const filterParams = filters;
    setExportingData(true);

    // Construct base query
    let query = `data/export-data-sent-to-excle`;
    const params = [];

    if (filterParams.customer)
      params.push(`customer=${encodeURIComponent(filterParams.customer)}`);
    if (filterParams.country)
      params.push(`country=${encodeURIComponent(filterParams.country)}`);
    if (filterParams.domain)
      params.push(`domainId=${encodeURIComponent(filterParams.domain)}`);
    if (filterParams.dataSource)
      params.push(`dataSource=${encodeURIComponent(filterParams.dataSource)}`);
    if (filterParams.search)
      params.push(
        `searchParamsData=${encodeURIComponent(filterParams.search)}`,
      );
    if (filterParams.dateRange) {
      const [startDate, endDate] = filterParams.dateRange;
      if (startDate && endDate) {
        params.push(`fromDate=${encodeURIComponent(startDate)}`);
        params.push(`toDate=${encodeURIComponent(endDate)}`);
      }
    }

    // Add parameters to the query string
    if (params.length) {
      query += `?${params.join('&')}`;
    }

    console.log('Final Query:', query);

    try {
      const response = await jwtAxios.get(query);
      return response.data; // The response should contain { data, fileName }
    } catch (error) {
      console.error('Error exporting data:', error);
      throw new Error(error.response?.data?.message || 'Failed to export data');
    } finally {
      setExportingData(false);
    }
  };

  return (
    <>
      <AppInfoView />
      {/* Data UI */}
      <DataUI
        dataToShow={dataToShow}
        pageSize={pageSize}
        setPageSize={setPageSize}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        rowCount={rowCount}
        dominId={dominId}
        onFilterChange={handleApplyFilters} // Changed to handleApplyFilters
        onEditCommit={onEditCommit}
        setPatchEdited={setPatchEdited}
        gettingData={gettingData}
        handleOnSelect={handleOnSelect}
        handleChange={handleChange}
        isFilterCardVisible={isFilterCardVisible}
        selected={selected}
        domainSelected={domainSelected}
        handleDomainChange={handleDomainChange}
        handleCustomerChange={handleCustomerChange} // Pass customer change handler
        allStatusCount={allStatusCount}
        doneStatusCount={doneStatusCount}
        errorStatusCount={errorStatusCount}
        setShowUploadModal={setShowUploadModal}
        handleCloseModal={handleCloseModal}
        showUploadModal={showUploadModal}
        onUploadSubmitting={onUploadSubmitting}
        onDropDownChange={onDropDownChange}
        onDropDownSearch={onDropDownSearch}
        selecteCustomer={selecteCustomer}
        affiliateId={affiliateIdWithoutNoApi}
        affiliateIdWithoutNoApi={affiliateId}
        errorData={errorData}
        userRole={user.user.role} // Ensure this is an array
        upLoadingData={upLoadingData}
        handleClear={handleClear}
        customerMapping={customerMapping} // Pass customer mapping here
        deleteData={deleteData}
        toggleFilterCardVisibility={toggleFilterCardVisibility}
        disableDataSource={disableDataSource}
        // Pass filter-related props
        filters={filters}
        exportingModalOpen={exportingModalOpen}
        exportingModal={exportingModal}
        exportingModalClose={exportingModalClose}
        setFilters={setFilters}
        scorcessOptions={scorcessOptions}
        handleDateRangeChange={handleDateRangeChange}
        handleSearchChange={handleSearchChange}
        countryOptions={countryOptions}
        handleClearFilters={handleClearFilters}
        exportDataToExcle={handleGenerateReport}
        archiveData={archiveData} // Pass archiveData as a prop to eliminate linter warning
        exportingData={exportingData}
        // Note: If DataUI needs more filter options, pass them accordingly
      />
    </>
  );
};

export default DataLG;
