import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Grid, TextField, Button, Box, Paper, Typography} from '@mui/material';
import {Autocomplete} from '@mui/material';
import {DateRangePicker, LocalizationProvider} from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns'; // Make sure you're using DateFns

const FilterCardSendData = ({
  countries,
  dataSources,
  domains,
  customers,
  handleCustomerChange,
  filters,
  setFilters,
  onApply,
  handleDateRangeChange,
  handleSearchChange,
  handleDomainChange,
  onClear,
  handleChange,
  disableDataSource,
}) => {
  // Effect to handle disabling Data Source
  useEffect(() => {
    if (disableDataSource) {
      setFilters((prev) => ({
        ...prev,
        dataSource: '',
      }));
    }
  }, [disableDataSource]);

  return (
    <Paper elevation={3} sx={{padding: 2, marginBottom: 2}}>
      <Grid container spacing={2} alignItems='center'>
        {/* Search Section */}
        <Grid item xs={12}>
          <Typography variant='h6' gutterBottom>
            Search
          </Typography>
          <TextField
            label='Search'
            value={filters.search}
            onChange={handleSearchChange}
            placeholder='Name, Mobile, Email'
            fullWidth
          />
        </Grid>

        {/* Date Range Section */}
        <Grid item xs={12}>
          <Typography variant='h6' gutterBottom>
            Sent Date Range
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateRangePicker
              startText='From Date'
              endText='To Date'
              value={filters.dateRange || [null, null]}
              onChange={handleDateRangeChange}
              renderInput={(startProps, endProps) => (
                <>
                  <TextField {...startProps} fullWidth sx={{mr: 2}} />
                  <TextField {...endProps} fullWidth />
                </>
              )}
            />
          </LocalizationProvider>
        </Grid>

        {/* Domain Dropdown Section */}
        <Grid item xs={12} sm={6} md={6}>
          <Typography variant='h6' gutterBottom>
            Domain
          </Typography>
          <Autocomplete
            disablePortal
            options={domains}
            getOptionLabel={(option) => option.label}
            value={domains.find((d) => d.key === filters.domain) || null}
            onChange={handleDomainChange}
            renderInput={(params) => (
              <TextField {...params} label='Domain' fullWidth />
            )}
          />
        </Grid>

        {/* Customer Dropdown Section */}
        <Grid item xs={12} sm={6} md={6}>
          <Typography variant='h6' gutterBottom>
            Customer
          </Typography>
          <Autocomplete
            disablePortal
            options={customers}
            getOptionLabel={(option) => option.label}
            value={customers.find((c) => c.key === filters.customer) || null}
            onChange={handleCustomerChange} // Use handleCustomerChange here
            renderInput={(params) => (
              <TextField {...params} label='Customer' fullWidth />
            )}
          />
        </Grid>

        {/* Country Dropdown Section */}
        <Grid item xs={12} sm={6} md={6}>
          <Typography variant='h6' gutterBottom>
            Country
          </Typography>
          <Autocomplete
            disablePortal
            options={countries}
            getOptionLabel={(option) => option.label}
            value={countries.find((c) => c.key === filters.country) || null}
            onChange={handleChange('country')}
            renderInput={(params) => (
              <TextField {...params} label='Country' fullWidth />
            )}
          />
        </Grid>

        {/* Data Source Dropdown Section */}
        <Grid item xs={12} sm={6} md={6}>
          <Typography variant='h6' gutterBottom>
            Data Source
          </Typography>
          <Autocomplete
            disablePortal
            options={dataSources}
            getOptionLabel={(option) => option.label}
            value={
              dataSources.find((s) => s.key === filters.dataSource) || null
            }
            onChange={handleChange('dataSource')}
            renderInput={(params) => (
              <TextField
                {...params}
                label='Data Source'
                fullWidth
                disabled={disableDataSource}
              />
            )}
          />
        </Grid>

        {/* Action Buttons */}
        <Grid item xs={12}>
          <Box display='flex' justifyContent='flex-end' gap={2}>
            <Button variant='contained' color='primary' onClick={onApply}>
              Apply Filters
            </Button>
            <Button variant='outlined' color='secondary' onClick={onClear}>
              Clear Filters
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

// Updated PropTypes to reflect changes
FilterCardSendData.propTypes = {
  countries: PropTypes.array.isRequired,
  dataSources: PropTypes.array.isRequired,
  domains: PropTypes.array.isRequired, // New prop for domains
  customers: PropTypes.array.isRequired, // New prop for customers
  filters: PropTypes.shape({
    country: PropTypes.string,
    dataSource: PropTypes.string,
    search: PropTypes.string,
    domain: PropTypes.string, // New field for domain
    customer: PropTypes.string, // New field for customer
    dateRange: PropTypes.arrayOf(PropTypes.instanceOf(Date)), // Expecting JavaScript Date objects
  }).isRequired,
  setFilters: PropTypes.func.isRequired,
  onApply: PropTypes.func.isRequired,
  handleDomainChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleCustomerChange: PropTypes.func.isRequired,
  disableDataSource: PropTypes.bool,
  handleSearchChange: PropTypes.func.isRequired,
  handleDateRangeChange: PropTypes.func.isRequired,
};

export default FilterCardSendData;
